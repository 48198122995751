export default {
    bill_number:     'رقم الفاتورة',
    bill_date:       'تاريخ إنشاء الفاتورة',
    ref_date:       'تاريخ المرجع',
    client_id:       'العميل',
    company_id:      'المنشئة',
    total:           'الاجمالي',
    vat:             'الضريبة',
    vat_percentage:  'نسبة الضريبة',
    final_total:     'الصافي',
    sign_id:         'الموقع',
    bank_id:         'المعلومات البنكية',
    posted:          'مرحلة',
    none_posted:     'غير مرحلة',
    posting:         'الترحيل',
};
