

export default {
  areYouSure: 'Are you sure?',
  sure_logout: 'Are you sure the account will be logged out?',
  exportAll: 'Are you sure to export all data?',
  confirmDelete: 'Are you sure to delete data?',
  error: 'An error occurred, please try again later.',
  whoops: 'Whoops',
  not_found_message: 'The request not found',
  window_blocked: 'Please make sure to allow pop-ups in your browser to be able to download files. It is recommended to use the Google Chrome browser for all the services and features of the program.',
  sessionExpired: 'The session has expired the page will be refreshed',
  formWasChanged: 'The data was modify',
}
