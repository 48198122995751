

export default {
  areYouSure: 'هل أنت متأكد؟',
  sure_logout: 'هل أنت متأكد سوف يتم تسجيل الخروج من الحساب',
  exportAll: 'هل أنت متأكد من تصدير جميع البيانات؟',
  confirmDelete: 'هل أنت متأكد من حذف البيانات؟',
  error: 'حدث خطأ الرجاء المحاولة لاحقاً',
  whoops: 'عذراً',
  not_found_message: 'لم يتم العثور على البيانات المطلوبة',
  window_blocked: 'الرجاء التأكد من سماح فتح النوافذ المنبثقه في المتصفح لتستطيع تحميل الملفات. ينصح باستخدام متصفح قوقل كروم (Google Chrome) للحصول على جميع الخدمات والمميزات في البرنامج.',
  sessionExpired: 'تم انتهاء صلاحية الجلسة سوف يتم تحديث الصفحة',
  formWasChanged: 'تم تعديل البيانات',
  false: 'معطل',
  true: 'مفعل',
}
